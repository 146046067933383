import text from "@/base/text/index.js";


const loadFirstClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = params.onlyFirst || false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const preloadFirstClosedPolls = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;

        if (polls.length > 1) {
            return;
        }

        dispatch(actions.polls.setClosedPollsLoading());

        const page = 0;
        const onlyFirst = false;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
            onlyFirst,
        });

        let closedPolls = [];
        let hasMorePages = false;

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        }

        dispatch(actions.polls.setClosedPolls({
            closedPolls,
            hasMorePages,
        }));
    };
};

const loadClosedPolls = (services, params) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setClosedPollsLoading());

        const page = params.page || 0;

        const res = await api.polls.getAllClosedPolls({
            session: state.user.session,
            page,
        });

        let closedPolls = [];
        let hasMorePages = false;
        let error = "";

        if (res.ok) {
            closedPolls = res.polls;
            hasMorePages = res.hasMore;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.polls.addClosedPolls({
            closedPolls,
            hasMorePages,
            error,
        }));
    };
};

const loadMoreClosedPolls = (services) => {
    return (dispatch, getState) => {
        const state = getState();

        const polls = state.polls.closedPolls;
        const page = state.polls.page || 0;

        if (page === 0 && polls.length < 10) {
            dispatch(loadFirstClosedPolls(services, {
                onlyFirst: false,
            }));
            return;
        }

        dispatch(loadClosedPolls(services, {
            page: page + 1,
        }));
    };
};

const submitPollAnswer = (services, params) => {
    const { api, actions, events } = services;
    const pollId = params.pollId;

    return async (dispatch, getState) => {
        const state = getState();

        const actionsCommonParams = {
            juiceId: params.juiceId,
            storyId: params.storyId,
            answerId: params.answerId,
        };

        if (params.isPreview) {
            dispatch(actions.juices.setJuicePollAnswered(actionsCommonParams));
            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswer(actionsCommonParams));
            return;
        }

        const session = state.user.session || "";

        events.student.selectPollAnswer({
            ...actionsCommonParams,
            session,
        });

        dispatch(actions.juiceStories.setStoryInfoStoryOpened(actionsCommonParams));
        dispatch(actions.juices.setJuicePollLoading(actionsCommonParams));

        const res = await api.polls.setPollAnswer({
            ...actionsCommonParams,
            session,
            pollId,
        });

        if (res.ok) {
            dispatch(actions.juices.setJuicePollLoaded(actionsCommonParams));
            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswer(actionsCommonParams));

            dispatch(loadPollResultById(services, { pollId }));
        } else {
            dispatch(actions.juices.setJuicePollError({
                ...actionsCommonParams,
                error: res.error || text.error,
            }));
        }
    };
};

const submitPollAnswerV2 = (services, params) => {
    const { api, actions, events } = services;
    const pollId = params.pollId || null;

    return async (dispatch, getState) => {
        const state = getState();

        const actionsCommonParams = {
            juiceId: params.juiceId,
            storyId: params.storyId,
            answerId: params.answerId,
        };

        if (params.isPreview) {
            dispatch(actions.juices.setJuicePollAnswered(actionsCommonParams));
            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswerV2(actionsCommonParams));
            return;
        }

        const session = state.user.session || "";

        events.student.selectPollAnswer({
            ...actionsCommonParams,
            session,
        });

        dispatch(actions.juiceStories.setStoryInfoStoryOpened(actionsCommonParams));
        dispatch(actions.juices.setJuicePollLoading(actionsCommonParams));

        const res = await api.polls.setPollAnswer({
            ...actionsCommonParams,
            session,
            pollId,
        });

        res.ok = true;

        if (res.ok) {
            dispatch(actions.juices.setJuicePollLoaded(actionsCommonParams));
            dispatch(actions.juiceStories.setStoryInfoStoryPollAnswerV2(actionsCommonParams));

            dispatch(loadPollResultById(services, { pollId }));
        } else {
            dispatch(actions.juices.setJuicePollError({
                ...actionsCommonParams,
                error: res.error || text.error,
            }));
        }
    };
};

const loadPollResultById = (services, params) => {
    const { api, actions } = services;
    const { pollId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.polls.setPollResultByIdLoading({ pollId }));

        const res = await api.polls.getPollResultById({
            session: state.user.session,
            pollId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.poll || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.polls.setPollResultById({
            pollId,
            data,
            error,
        }));
    };
};

export default {
    loadFirstClosedPolls,
    preloadFirstClosedPolls,
    loadPollResultById,
    loadClosedPolls,
    loadMoreClosedPolls,
    submitPollAnswer,
    submitPollAnswerV2,
};
